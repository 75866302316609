@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
/* @import url("https://cdn.jsdelivr.net/npm/locomotive-scroll@3.5.4/dist/locomotive-scroll.css"); */
@import url("https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("http://fonts.cdnfonts.com/css/digital-numbers");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --accent-primary: linear-gradient(90deg, #0099e5 0%, #3354ff 100%);
  --link-primary: #3354ff;
  --secondary-yellow: #fbcfbb;
  --content-primary: #0d0e13;
  --content-secondary: #5f616d;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
  scroll-behavior: smooth;
  text-decoration: none;
}

html {
  cursor: url("https://d39w1it2s36axc.cloudfront.net/icons/cursor.svg"), auto;
}

body {
  color: #0d0e13;
  background-color: #fff;
  overflow-x: hidden;
}

.homeC {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error_text {
  position: absolute;
  z-index: 111;
  max-width: 450px;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
}
.error_text h5 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.error_page,
.network_page {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background: #fbfdfe;
}

.sarath_hover,
.hola_hover {
  position: relative;
}

.hola_hover::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 256px;
  width: 256px;
  transform: translate(-65%, -65%) scale(0);
  transition: 0.5s ease;
  background-image: url("https://d39w1it2s36axc.cloudfront.net/icons/wave.gif");
}

.sarath_hover::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 256px;
  width: 256px;
  transform: translate(65%, -65%) scale(0);
  transition: 0.5s ease;
  background-image: url("https://d39w1it2s36axc.cloudfront.net/icons/smile.gif");
}

.hola_hover:hover::before {
  /* opacity: 0; */
  transform: translate(-65%, -65%) scale(0.5);
}

.sarath_hover:hover::before {
  /* opacity: 0; */
  transform: translate(65%, -65%) scale(0.4);
}

.Interactions_page,
.Course_Projects_page {
  width: 100vw;
  height: auto;
  position: relative;
}

.project-ctas {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

/*      Interactions      */

.Course_Projects-home,
.interactions_section-home {
  display: flex;
  flex-direction: row;
  gap: 24px;
  height: auto;
  margin: 10rem 0;
}

.home-is {
  width: 100%;
  max-width: 252px;
  height: auto;
  border-radius: 24px;
  transition: 0.5s ease;
  overflow: hidden;
  padding: 1rem;
  background: #fafdff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.home-ism {
  width: 100%;
  max-width: 368px;
  height: 100%;
  border-radius: 24px;
  transition: 0.5s ease;
  overflow: hidden;
  padding: 1rem;
  background: #fafdff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.home-isb {
  width: 100%;
  max-width: 368px;
  border-radius: 24px;
  transition: 0.5s ease;
  overflow: hidden;
  padding: 1rem;
  background: #fafdff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.home-isb a {
  width: 80%;
}
.home-isb button {
  width: 100%;
  margin: 0;
}

.home-ism .bunker_interaction {
  width: 90%;
}

/*      Certifications      */

.certifications-page {
  width: 100vw;
  height: auto;
  position: relative;
}

.certifications-slider {
  display: grid;
  place-items: center;
  width: 100vw;
  position: relative;
  margin: 10rem 0;
}

.certifications-slider .certifications {
  width: calc(360px * 10);
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  font-size: 0;
  -webkit-animation: scrollCertifications 70s linear infinite;
  animation: scrollCertifications 70s linear infinite;
}

@-webkit-keyframes scrollCertifications {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(calc(-360px * 4)); /* Halfway point */
  }
  100% {
    transform: translateX(
      calc(-360px * 8)
    ); /* Double the distance for smoother transition */
  }
}

@keyframes scrollCertifications {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(calc(-360px * 4)); /* Halfway point */
  }
  100% {
    transform: translateX(
      calc(-360px * 8)
    ); /* Double the distance for smoother transition */
  }
}

.certifications-slider ul:hover {
  animation-play-state: paused;
}

.certification-frame {
  width: 360px;
  min-width: 360px;
  max-width: 380px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 20px;
}

.certification-front {
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 24px;
  border-radius: 24px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 4px 48px 0px rgba(28, 27, 44, 0.06);
  box-sizing: border-box;
}

.certification-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.certification-text h2 {
  font-size: 24px;
  font-weight: 500;
  min-height: 60px;
  text-wrap: wrap;
}

.certification-text p {
  font-size: 18px;
  font-weight: 400;
  color: var(--content-secondary);
}

.certification-front img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 20px;
  border: 1px solid #eee;
}

.certification-back {
  width: 90%;
  position: relative;
  z-index: 0;
  height: auto;
  border-radius: 24px;
  padding: 24px;
  background: #fff;
  box-shadow: 0px 4px 48px 0px rgba(28, 27, 44, 0.08);
  transform: translateY(-80px);
  box-sizing: border-box;
}

.certification-back .deck-btn {
  width: 100%;
  height: 48px;
  font-weight: 400;
}

.certification-frame:hover .certification-back {
  animation: certification_button_hover 0.5s ease-out forwards;
}

.certification-frame .certification-back {
  animation: certification_button_default 0.5s ease-in forwards;
}

@keyframes certification_button_hover {
  0% {
    transform: translateY(-80px);
    z-index: 0;
  }
  49% {
    transform: translateY(0px);
    z-index: 0;
  }
  50% {
    transform: translateY(0px);
    z-index: 1;
  }
  100% {
    transform: translateY(-20px);
    z-index: 1;
  }
}

@keyframes certification_button_default {
  0% {
    transform: translateY(-20px);
    z-index: 1;
  }
  49% {
    transform: translateY(0px);
    z-index: 1;
  }
  50% {
    transform: translateY(0px);
    z-index: 0;
  }
  100% {
    transform: translateY(-80px);
    z-index: 0;
  }
}

.stack-container {
  width: 100vw;
  height: auto;
  position: relative;
}
.iBgo,
.oBgo,
.mBgo,
.iBg,
.oBg,
.mBg {
  position: absolute;
}
.oBg {
  width: 75%;
  z-index: -6;
  animation-delay: 0.8s !important;
  animation: oBg 4s infinite cubic-bezier(0.66, 0.53, 0.38, 1.45);
}
.mBg {
  width: 50%;
  z-index: -5;
  animation-delay: 0.4s !important;
  animation: mBg 4s infinite cubic-bezier(0.66, 0.53, 0.38, 1.45);
}
.iBg {
  width: 25%;
  z-index: -4;
  animation: iBg 4s infinite cubic-bezier(0.66, 0.53, 0.38, 1.45);
}

.oBgo {
  width: 75%;
  z-index: -3;
  opacity: 0;
  animation-delay: 0.8s !important;
  animation: oBgo 4s infinite cubic-bezier(0.66, 0.53, 0.38, 1.45);
}
.mBgo {
  width: 50%;
  z-index: -2;
  opacity: 0;
  animation-delay: 0.4s !important;
  animation: mBgo 4s infinite cubic-bezier(0.66, 0.53, 0.38, 1.45);
}
.iBgo {
  width: 25%;
  z-index: -1;
  opacity: 0;
  animation: iBgo 4s infinite cubic-bezier(0.66, 0.53, 0.38, 1.45);
}

@keyframes oBg {
  0% {
    width: 75%;
  }
  15% {
    width: 75%;
  }
  25% {
    width: 80%;
  }
  35% {
    width: 75%;
  }
  100% {
    width: 75%;
  }
}
@keyframes mBg {
  0% {
    width: 50%;
  }
  15% {
    width: 50%;
  }
  25% {
    width: 55%;
  }
  35% {
    width: 50%;
  }
  100% {
    width: 50%;
  }
}
@keyframes iBg {
  0% {
    width: 25%;
  }
  15% {
    width: 25%;
  }
  25% {
    width: 30%;
  }
  30% {
    width: 30%;
  }
  40% {
    width: 25%;
  }
  100% {
    width: 25%;
  }
}

@keyframes oBgo {
  0% {
    width: 75%;
    opacity: 0;
  }
  15% {
    width: 75%;
    opacity: 0;
  }
  25% {
    width: 80%;
    opacity: 1;
  }
  35% {
    width: 75%;
    opacity: 0;
  }
  100% {
    width: 75%;
    opacity: 0;
  }
}
@keyframes mBgo {
  0% {
    width: 50%;
    opacity: 0;
  }
  15% {
    width: 50%;
    opacity: 0;
  }
  25% {
    width: 55%;
    opacity: 1;
  }
  35% {
    width: 50%;
    opacity: 0;
  }
  100% {
    width: 50%;
    opacity: 0;
  }
}
@keyframes iBgo {
  0% {
    width: 25%;
    opacity: 0;
  }
  15% {
    width: 25%;
    opacity: 0;
  }
  25% {
    width: 30%;
    opacity: 1;
  }
  35% {
    width: 25%;
    opacity: 0;
  }
  100% {
    width: 25%;
    opacity: 0;
  }
}

.border-breathe {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reactpng {
  width: 96px;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.jspng {
  width: 76px;
  position: absolute;
  left: 38%;
  top: 28%;
  animation: js 4s infinite cubic-bezier(0.66, 0.53, 0.38, 1.45);
}
.gsappng {
  width: 76px;
  position: absolute;
  left: 38%;
  bottom: 28%;
  animation: gsap 4s infinite cubic-bezier(0.66, 0.53, 0.38, 1.45);
}

.blenderpng {
  width: 66px;
  position: absolute;
  left: 23%;
  top: 46%;
  animation-delay: 0.4s !important;
  animation: blender 4s infinite cubic-bezier(0.66, 0.53, 0.38, 1.45);
}

.aipng {
  width: 56px;
  position: absolute;
  left: 15%;
  top: 10%;
  animation-delay: 0.8s !important;
  animation: js 4s infinite cubic-bezier(0.66, 0.53, 0.38, 1.45);
}
.pspng {
  width: 56px;
  position: absolute;
  left: 15%;
  bottom: 10%;
  animation-delay: 0.8s !important;
  animation: gsap 4s infinite cubic-bezier(0.66, 0.53, 0.38, 1.45);
}

.hours {
  position: absolute;
  left: 57%;
  top: 28%;
  animation: jsr 4s infinite cubic-bezier(0.66, 0.53, 0.38, 1.45);
  width: 76px;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  color: #fff;
  font-size: 1.5rem;
  transform-origin: top;
  transition: 0.5s ease;
}
.thinking {
  position: absolute;
  left: 57%;
  bottom: 28%;
  animation: gsapr 4s infinite cubic-bezier(0.66, 0.53, 0.38, 1.45);
  width: 76px;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  font-size: 1.5rem;
  transform-origin: top;
  transition: 0.5s ease;
}
.insp {
  position: absolute;
  right: 23%;
  top: 46%;
  animation-delay: 0.4s !important;
  animation: blenderr 4s infinite cubic-bezier(0.66, 0.53, 0.38, 1.45);
  width: 66px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  color: #fff;
  font-size: 1.5rem;
  transform-origin: top;
  transition: 0.5s ease;
}
.lines {
  position: absolute;
  right: 15%;
  top: 10%;
  animation-delay: 0.8s !important;
  animation: jsr 4s infinite cubic-bezier(0.66, 0.53, 0.38, 1.45);
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  font-size: 1.2rem;
  transform-origin: top;
  transition: 0.5s ease;
}
.files {
  position: absolute;
  right: 15%;
  bottom: 10%;
  animation-delay: 0.8s !important;
  animation: gsapr 4s infinite cubic-bezier(0.66, 0.53, 0.38, 1.45);
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  font-size: 1.2rem;
  transform-origin: top;
  transition: 0.5s ease;
}

.hover-insp,
.hover-lines,
.hover-files,
.hover-thinking,
.hover-hours {
  text-align: center;
  font-size: 0.72rem;
  font-weight: 400;
  display: none;
}

.insp:hover,
.lines:hover,
.files:hover,
.thinking:hover,
.hours:hover {
  cursor: none;
  height: 92px;
}

.insp:hover .hover-insp,
.lines:hover .hover-lines,
.files:hover .hover-files,
.thinking:hover .hover-thinking,
.hours:hover .hover-hours {
  display: block;
}

@keyframes jsr {
  0% {
    transform: translate(0px, 0px);
  }
  15% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(20px, -20px);
  }
  35% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes js {
  0% {
    transform: translate(0px, 0px);
  }
  15% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(-20px, -20px);
  }
  35% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes gsapr {
  0% {
    transform: translate(0px, 0px);
  }
  15% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(20px, 20px);
  }
  35% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes gsap {
  0% {
    transform: translate(0px, 0px);
  }
  15% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(-20px, 20px);
  }
  35% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes blenderr {
  0% {
    transform: translate(0px, 0px);
  }
  15% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(20px, 0px);
  }
  35% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes blender {
  0% {
    transform: translate(0px, 0px);
  }
  15% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(-20px, 0px);
  }
  35% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.breathe {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: -10;
}

.stack-stuff {
  width: 100%;
  height: 100%;
  position: absolute;
}

.stackH3 {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.stack-icons {
  width: 100%;
  height: 100%;
  position: relative;
}

.error-bg {
  width: 100%;
  height: 100%;
  background: url("https://d39w1it2s36axc.cloudfront.net/images/images/404_desktop.png")
    no-repeat center;
  background-size: cover;
}

/* .network-bg{
  width: 100%;
  height: 100%;
  background: url("/src/images/no_internet.svg") no-repeat center;
  background-size: cover;
} */

.network-bg {
  display: flex;
  justify-content: center;
  height: 60vh;
}

.network-bg img {
  top: 120px;
}

.pre-container {
  position: fixed;
  z-index: 2000000;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  overflow: hidden;
  opacity: 1;
  transform: scaleY(1);
  transform-origin: top;
  animation: preC 1s ease forwards;
  animation-iteration-count: 1;
  animation-delay: 3.5s;
  cursor: none;
}

@keyframes preC {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  99% {
    opacity: 0;
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}

.c1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  animation: c1 3s ease forwards;
  animation-delay: 1.5s;
}

.c2 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(0deg) scale(0.5);
  animation: c2 3s ease forwards;
  animation-delay: 1.5s;
}
.c3 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  animation: c3 3s ease forwards;
  animation-delay: 1.5s;
}

#c-outer {
  position: absolute;
  z-index: 5;
}
#c-inner {
  position: absolute;
  z-index: 15;
}

#c-outer {
  animation: pac 1.5s ease;
}

#co1 {
  animation: co1 1.5s ease both;
  transform-origin: left;
}
#co2 {
  animation: co2 1.5s ease both;
  transform-origin: left;
}

@keyframes c1 {
  25% {
    transform: translate(-25%, -50%) scale(0.5);
  }
  50% {
    transform: translate(-25%, -50%) scale(0.5);
  }
  75% {
    transform: translate(-25%, -50%) scale(0.5);
    top: 50%;
    left: 50%;
  }
  100% {
    transform: translate(-25%, -50%) scale(0.5);
    top: 13%;
    left: 18%;
  }
}
@keyframes c3 {
  25% {
    transform: translate(-75%, -50%) scale(0.5);
  }
  50% {
    transform: translate(-75%, -50%) rotate(180deg) scale(0.5);
  }
  75% {
    transform: translate(-75%, -50%) rotate(180deg) scale(0.5);
    top: 50%;
    left: 50%;
  }
  100% {
    transform: translate(-75%, -50%) rotate(180deg) scale(0.5);
    top: 13%;
    left: 18%;
  }
}

@keyframes c2 {
  25% {
    transform: translate(-75%, -50%) scale(0.5);
  }
  50% {
    transform: translate(-75%, -75%) scale(0.5);
  }
  75% {
    transform: translate(-75%, -75%) scale(0.5);
    top: 50%;
    left: 50%;
  }
  100% {
    transform: translate(-75%, -75%) scale(0.5);
    top: 13%;
    left: 18%;
  }
}

@keyframes pac {
  0% {
    transform: translate(4377px, 4721px) rotate(-90deg);
  }
  25% {
    transform: translate(4377px, 4667px) rotate(-90deg);
  }
  50% {
    transform: translate(4377px, 4721px) rotate(-90deg);
  }
  75% {
    transform: translate(4377px, 4667px) rotate(-90deg);
  }
  100% {
    transform: translate(4377px, 4721px) rotate(-90deg);
  }
}
@keyframes co1 {
  0% {
    d: path("M62.5,0,125,62H0Z");
  }
  25% {
    d: path("M8.5,0,17,62H0Z");
  }
  50% {
    d: path("M62.5,0,125,62H0Z");
  }
  75% {
    d: path("M8.5,0,17,62H0Z");
  }
  100% {
    d: path("M62.5,0,125,62H0Z");
  }
}
@keyframes co2 {
  0% {
    d: path(
      "M 62.49989700317383 0 L 125 62 L -7.62939453125e-06 62 L 62.49989700317383 0 Z"
    );
  }
  25% {
    d: path(
      "M 8.499996185302734 0 L 16.99999618530273 62 L -3.814697265625e-06 62 L 8.499996185302734 0 Z"
    );
  }
  50% {
    d: path(
      "M 62.49989700317383 0 L 125 62 L -7.62939453125e-06 62 L 62.49989700317383 0 Z"
    );
  }
  75% {
    d: path(
      "M 8.499996185302734 0 L 16.99999618530273 62 L -3.814697265625e-06 62 L 8.499996185302734 0 Z"
    );
  }
  100% {
    d: path(
      "M 62.49989700317383 0 L 125 62 L -7.62939453125e-06 62 L 62.49989700317383 0 Z"
    );
  }
}

.transition-yellow {
  content: "";
  position: absolute;
  z-index: 100000000;
  transform: scaleY(0);
  transition: 1s;
  transform-origin: bottom;
  background: var(--accent-primary);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.home {
  overflow: hidden;
}

.homer {
  overflow: hidden;
}

li {
  list-style: none;
  text-decoration: none;
}

.navbar-container {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.navbar {
  height: 20vh;
  width: 90%;
  max-width: 1024px;
  display: flex;
  align-items: center;
}

.logoC {
  width: 120px;
  cursor: pointer;
}

.footer a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navlogo_right,
.navlogo_top,
.navlogo_bottom {
  transform: translate(0px, 0px) rotate(0deg);
  transform-origin: center;
  transform-box: fill-box;
}
#Subtraction_10 {
  /* transform:  rotate(180deg); */
  transform-origin: center;
  transform-box: fill-box;
}
.sub6 {
  /* transform: rotate(0deg); */
  transform-origin: center;
  transform-box: fill-box;
}

.navlogo_rotate {
  animation: navlogo_rotate 2s forwards;
}

@keyframes navlogo_rotate {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(360deg);
  }
}

.logoC img {
  height: 40px;
}
.menu {
  margin: auto;
  display: flex;
  align-items: center;
}

.menu-bg {
  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 3;
  font-size: 1rem;
  text-decoration: none;
  color: var(--content-secondary);
  padding: 0.75rem 0.75rem;
  margin: 0 0.75rem;
  font-weight: 400;
  overflow: hidden !important;
  transition: color 0.4s ease-in-out;
}

.desktop-d-none {
  display: none;
}

.mobile-d-none {
  display: block;
}

/* .menu a:hover {
  cursor: pointer;
  color: var(--content-primary);
} */

.menu-bg::before {
  position: absolute;
  z-index: -1;
  content: "";
  background-color: rgba(0, 153, 229, 0.25);
  height: 40%;
  width: 100%;
  left: 0;
  bottom: 0.25rem;
  transform: translateY(30px);
  transition: transform 0.4s;
}

.menu-bg:hover::before {
  transform: translateY(0px);
}

.active-menu::before {
  transform: translateY(0px);
}

small {
  position: absolute;
  left: 12px;
  top: 70px;
  color: #ff6161;
  font-weight: 500;
  display: none;
}

.active-menu {
  color: #1f1f1f !important;
}

.center {
  height: 60vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.main-header {
  font-size: 6rem;
  font-weight: 700;
  /* overflow: hidden; */
  text-align: center;
}

.project-1 .main-header {
  font-size: 1rem !important;
}

.main-header span {
  display: inline-block;
  white-space: pre;
  -webkit-background-clip: text;
  color: transparent;

  /* font-family: "Quattrocento", serif !important; */
}

.main-header span:nth-child(1) {
  background-image: linear-gradient(90deg, #0099e5, #0c8aec);
}
.main-header span:nth-child(2) {
  background-image: linear-gradient(90deg, #0c8aec, #1480f0);
}
.main-header span:nth-child(3) {
  background-image: linear-gradient(90deg, #1480f0, #246af8);
}
.main-header span:nth-child(4) {
  background-image: linear-gradient(90deg, #246af8, #3354ff);
}

::selection {
  background: rgba(0, 153, 229, 0.3);
}

.center p {
  margin-top: 12px;
  font-size: 1.5rem;
  color: var(--content-secondary);
}

.center p span {
  position: absolute;
  color: var(--content-primary);
  font-weight: 600;
  background-color: #fbfdfe;
  padding: 0 12px;
}

.para {
  width: 90%;
  max-width: 1024px;
  display: flex;
  text-align: center;
  color: var(--content-secondary);
  flex-direction: column;
}

.cube-container {
  perspective: 500px;
  transform-origin: 50% 50%;
  overflow: hidden;
}

.cube {
  transform-style: preserve-3d;
  width: 180px;
  height: 34px;
  position: relative;
  animation: loop 6s infinite linear;
}

.front-name {
  display: block;
  backface-visibility: hidden;
  transform: rotateX(0deg) translateZ(20px);
}

.top-name {
  top: 0;
  left: 0;
  transform: rotateX(90deg) translateZ(20px);
}

.back-name {
  top: 0;
  left: 0;
  transform: rotateX(180deg) translateZ(20px);
}
.bottom-name {
  top: 0;
  left: 0;
  transform: rotateX(270deg) translateZ(20px);
}

@keyframes loop {
  0% {
    transform: rotateX(0deg);
  }
  35% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(180deg);
  }
  85% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}

.go-down {
  cursor: pointer;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  transform: scale(0.7);
  transition: 0.6s ease-in-out;
}

.go-down.gone-down {
  opacity: 0;
}

.project-image {
  max-width: 100%;
  animation: breathe 4s ease-in-out infinite alternate;
}
@keyframes breathe {
  from {
    transform: translateY(0px) scale(1.02);
  }
  to {
    transform: translateY(5px) scale(1.05);
  }
}

Link {
  cursor: none;
}

.project-new-container {
  width: 100%;
  max-width: 1024px;
  box-sizing: border-box;
  z-index: 2;
  height: 540px;
  /* background: #F0F6FE; */
  border-radius: 24px;
  box-shadow: 0px 16px 64px rgba(13, 14, 19, 0.08);
  padding: 3rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  color: var(--content-primary);
  transition: ease 0.3s all;
}

.project-new-container:hover {
  padding: 3.5rem;
}

/* .project-new-container:nth-child(1){
  background: #000;
} */

.p-0 {
  background: #f1f3fe;
  position: relative;
  justify-content: flex-end;
  align-items: flex-start;
  color: #1f2569;
}

.p-0 p {
  margin-bottom: 0rem !important;
  font-size: 1.5rem;
  z-index: 5;
}

.p-0 img:first-of-type {
  width: 40%;
  position: absolute;
  left: 120px;
  bottom: 160px;
  filter: drop-shadow(0 8px 48px rgba(6, 80, 177, 0.2));
  animation: breathe 4s ease-in-out infinite alternate;
}

.p-0 img:last-child {
  height: calc(100% - 160px);
  position: absolute;
  right: -33%;
  top: 60px;
  filter: drop-shadow(-8px 0 48px rgba(6, 80, 177, 0.2));
  animation: breathe 4s ease-in-out infinite alternate;
}

.p-1 {
  align-items: flex-start;
  background: #fdf6f2;
  color: #3b2313;
}

.p-1 img {
  width: 70%;
  position: absolute;
  right: -22%;
  top: 240px;
  filter: drop-shadow(-8px 0 48px rgba(58, 34, 19, 0.2));
  animation: breathe 4s ease-in-out infinite alternate;
}

.project-new-container h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.project-new-container p {
  margin-bottom: 5rem;
  font-size: 1rem;
}

.p-2 {
  background: #f2f7fd;
  color: #0650b1;
}

.p-2 img {
  width: 70%;
  height: auto;
  filter: drop-shadow(0 -8px 48px rgba(6, 80, 177, 0.3));
  animation: breathe 4s ease-in-out infinite alternate;
}

.p-3 {
  background: #fdfbf2;
  color: #3e3100;
  align-items: flex-end;
}

.p-3 img {
  width: 70%;
  position: absolute;
  left: -22%;
  top: 240px;
  filter: drop-shadow(-8px 0 48px rgba(58, 34, 19, 0.2));
  animation: breathe 4s ease-in-out infinite alternate;
}

.project {
  width: 100%;
  max-width: 1024px;
  border-radius: 1rem;
  height: 600px;
  left: 3rem;
  overflow: hidden;
  box-shadow: 0px 4px 24px rgba(18, 45, 52, 0.08);
  cursor: none !important;
}

.video-project {
  width: 35rem;
  height: 100%;
  left: 3rem;
  overflow: hidden;
  cursor: none !important;
}

.videos-cont {
  position: fixed;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  transition: 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) all;
}

.videos-cont.activevbg {
  display: block;
}

.videos {
  position: fixed;
  z-index: 1000;
  background-color: #0d0e13;
  width: 80%;
  top: 94%;
  left: 10%;
  border-radius: 6px;
  height: 90%;
  opacity: 0.8;
  transition: 1.3s cubic-bezier(0.19, 1, 0.22, 1) top;
}

.videos.activev {
  opacity: 1;
  width: 90%;
  top: 5%;
  left: 5%;
}

@import url("https://fonts.googleapis.com/css2?family=Ephesis&display=swap");

.pitch {
  background: rgb(0, 0, 0, 0.75);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform-origin: top;
  z-index: 2;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
}

.p-1,
.p-2,
.p-3,
.p-4,
.p-5 {
  opacity: 0;
  transform: translateY(60px) scale(0.8);
  transition: 0.3s cubic-bezier(0.59, 0.33, 0.29, 0.88);
}

.webgl {
  position: absolute !important;
  z-index: -1;
  top: 0;
  width: 80vw !important;
  height: 70vh !important;
}

.gnctd_figma_frame {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gnctd_figma {
  width: 80%;
  height: 80%;
  border-radius: 40px;
  border: 0;
  overflow: hidden;
}

.works {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw !important;
  gap: 2rem;
  padding: 3rem 0rem;
}

.projects {
  gap: 2rem;
  display: flex;
  flex-direction: column;
}

.movable-header {
  font-size: 4rem;
  color: transparent;
  width: fit-content;
  margin-bottom: 2rem;
  /* background-image: linear-gradient(90deg,#425EF0, #42B6F0, #D342F0, #F04299);  */
  background-image: linear-gradient(90deg, #0099e5 0%, #3354ff 100%);
  -webkit-background-clip: text;
}

/* .movable-header-span:hover{
  transform: rotateY(30deg);
} */

.code-works {
  background: var(--content-primary);
  width: 100%;
  padding: 3rem 0rem;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.code-works-container {
  width: 100%;
  max-width: 1024px;
  height: 1024px;
  padding: 3rem 0rem;
  box-sizing: border-box;
  position: relative;
}

.code-works-container div:nth-child(1) {
  position: absolute;
  width: 320px;
  height: 320px;
  border-radius: 24px;
  left: 50%;
  top: 50%;
  background-color: #26272b;
  transform: translate(-50%, -50%);
}

.code-works-container div:nth-child(2) {
  position: absolute;
  width: 320px;
  height: 672px;
  border-radius: 24px;
  background-color: #26272b;
  left: 0px;
  top: 0px;
}
.code-works-container div:nth-child(3) {
  position: absolute;
  height: 320px;
  width: 672px;
  border-radius: 24px;
  background-color: #26272b;
  right: 0px;
  top: 0px;
}
.code-works-container div:nth-child(4) {
  position: absolute;
  height: 320px;
  width: 672px;
  border-radius: 24px;
  background-color: #26272b;
  left: 0px;
  bottom: 0px;
}
.code-works-container div:nth-child(5) {
  position: absolute;
  width: 320px;
  height: 672px;
  border-radius: 24px;
  background-color: #26272b;
  right: 0px;
  bottom: 0px;
}

.project-cursor {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: var(--accent-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: zoomOut 0.4s ease-in-out;
  position: absolute;
  visibility: hidden;
}

.project-cursor p {
  font-weight: 600;
  color: var(--content-primary);
  font: 1rem;
}

.personas {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.persona-conts {
  width: 45%;
}

.p-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 3rem;
}

.details {
  margin-left: 24px;
}

.p-details img {
  height: 180px;
}

.cursor-follower {
  position: fixed;
  z-index: 100000;
  color: white;
  background: var(--accent-primary);
  opacity: 0.5;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  transform: scale(1);
  transition: 0.8s cubic-bezier(0.58, 0.34, 0.54, 1.94) transform,
    0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) opacity;
  pointer-events: none;
}

.cursor-follower.active {
  opacity: 0.98;
  transform: scale(3);
}

.cursor-follower.activehire {
  opacity: 0.88;
  transform: scale(3);
}

.cursor-follower.removed {
  cursor: none !important;
  background-color: transparent;
  opacity: 0.98;
  transform: scale(3);
}

.resource-cursor,
.hire,
.case {
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.3rem;
  font-weight: bold;
}

.hire img {
  width: 30px;
  position: absolute;
  animation: rotator 2s infinite ease;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.contactButton {
  opacity: 1;
  height: 48px;
  width: 120px;
  border-radius: 20px;
  outline: none;
  border: none;
  font-size: 1rem;
  font-weight: 400;
  /* background: var(--accent-primary); */
  cursor: pointer;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s linear;
}

.contactButton::before {
  box-sizing: border-box;
  position: absolute;
  content: "";
  z-index: 0;
  height: 48px;
  width: 48px;
  border-radius: 24px;
  background: transparent;
  border: 2px solid #eee;
  transition: 0.5s ease all;
}

.contactButton:hover::before {
  width: 120px;
  z-index: 1;
  background: var(--accent-primary);
  color: white !important;
  border: 2px solid #fff;
}

.contactButton:hover a {
  z-index: 10;
  color: white !important;
}

.contactButton a {
  position: relative;
  color: var(--link-primary) !important;
  z-index: 2;
}

.primaryButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.primaryButton,
button {
  left: 0;
  height: 48px;
  width: 120px;
  border-radius: 24px;
  outline: none;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  background: var(--accent-primary);
  color: white !important;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

button {
  z-index: 5;
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.primaryButton::before,
button::before {
  content: "";
  background: rgba(0, 0, 0, 0.1);
  height: 0%;
  width: 120%;
  left: 0;
  top: 0;
  position: absolute;
  transition: 0.8s ease-in-out;
  border-radius: 50% 50% 50% 50%;
  transform: translate(-10%, -50%);
}

.primaryButton:hover::before,
button:hover::before {
  color: var(--accent-primary);
  height: 350%;
  transform: translateY(0);
  border-radius: 0 0 0 0;
}

.flight {
  z-index: 2;
  position: absolute;
  transform: translate(-182px, 182px) scale(0.1);
}

.resource-cursor img,
.case img {
  width: 8px;
}

.pin-spacer {
  pointer-events: all;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  position: sticky;
  top: 0;
  /* background-image: linear-gradient(60deg,#425EF0, #1692CF, #D342F0, #F04299);  */
  background-image: linear-gradient(60deg, #0099e5 0%, #3354ff 100%);
  background-size: 250%;
  background-position: left;
  animation: backgroundChange ease 20s both infinite alternate;
}

@keyframes backgroundChange {
  0% {
    background-position: left;
    /* background-image: linear-gradient(30deg,#425EF0, #1692CF, #D342F0, #F04299);  */
    background-image: linear-gradient(30deg, #0099e5 0%, #3354ff 100%);
  }
  100% {
    background-position: right;
    /* background-image: linear-gradient(60deg,#425EF0, #3590be, #D342F0, #F04299);  */
    background-image: linear-gradient(90deg, #0099e5 0%, #3354ff 100%);
  }
}

.s-i:hover {
  cursor: none;
  color: var(--accent-primary);
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;
}

.input-container {
  padding-top: 1.5rem;
  position: relative;
  padding-bottom: 1.5rem;
  overflow: hidden;
}
.input-container label {
  color: #fefefe !important;
  position: absolute;
  left: 0;
  transform: translate(12px, 16px);
  transition: 0.3s ease transform;
  pointer-events: none;
}
.input-container input,
.input-container textarea {
  width: 512px;
  height: 40px;
  padding-left: 1rem;
  border: none;
  color: #fefefe;
  background: transparent;
  border-bottom: 1px solid #fefefe;
}

.input-container input:focus,
.input-container textarea:focus {
  outline: none;
}

.nameInput {
  position: relative;
}

.b1,
b2,
b3,
b4 {
  pointer-events: none;
}

.borderLeft {
  width: 2px;
  height: 40px;
  background: var(--accent-primary);
  position: absolute;
  left: 0;
  animation: bleft 1s both;
  transform-origin: bottom;
}
.borderTop {
  width: 526px;
  height: 2px;
  background: var(--accent-primary);
  position: absolute;
  left: 0;
  animation: btop 1s both;
  transform-origin: left;
  animation-delay: 0.5s;
}
.borderRight {
  width: 2px;
  height: 40px;
  background: var(--accent-primary);
  position: absolute;
  right: 0;
  animation: bright 1s both;
  transform-origin: top;
  animation-delay: 1s;
}
.borderBottom {
  width: 526px;
  height: 2px;
  background: var(--accent-primary);
  position: absolute;
  right: 0;
  bottom: 24px;
  animation: bbottom 1s both;
  transform-origin: right;
  animation-delay: 1.5s;
}

.borderLeftm {
  width: 2px;
  height: 80px;
  background: var(--accent-primary);
  position: absolute;
  left: 0;
  animation: bleft 1s both;
  transform-origin: bottom;
}
.borderRightm {
  width: 2px;
  height: 80px;
  background: var(--accent-primary);
  position: absolute;
  right: 0;
  animation: bright 1s both;
  transform-origin: top;
  animation-delay: 1s;
}
.borderBottomm {
  width: 526px;
  height: 2px;
  background: var(--accent-primary);
  position: absolute;
  right: 0;
  bottom: 28px;
  animation: bbottomm 1s both;
  transform-origin: right;
  animation-delay: 1.5s;
}

@keyframes bleft {
  0% {
    transform: scaleY(0);
    transform-origin: bottom;
  }
  49% {
    transform: scaleY(1);
    transform-origin: bottom;
  }
  50% {
    transform: scaleY(1);
    transform-origin: top;
  }
  100% {
    transform: scaleY(0);
    transform-origin: top;
  }
}
@keyframes btop {
  0% {
    transform: scaleX(0);
    transform-origin: left;
    animation-fill-mode: backwards;
  }
  49% {
    transform: scaleX(1);
    transform-origin: left;
  }
  50% {
    transform: scaleX(1);
    transform-origin: right;
    animation-fill-mode: forwards;
  }
  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
}

@keyframes bright {
  0% {
    transform: scaleY(0);
    transform-origin: top;
  }
  49% {
    transform: scaleY(1);
    transform-origin: top;
  }
  50% {
    transform: scaleY(1);
    transform-origin: bottom;
  }
  100% {
    transform: scaleY(0);
    transform-origin: bottom;
  }
}
@keyframes bbottom {
  0% {
    transform: scaleX(0);
    transform-origin: right;
    animation-fill-mode: backwards;
  }
  49% {
    transform: scaleX(1);
    transform-origin: right;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left;
    animation-fill-mode: forwards;
  }
  100% {
    transform: scaleX(0);
    transform-origin: left;
  }
}
@keyframes bbottomm {
  0% {
    transform: scaleX(0);
    transform-origin: right;
    animation-fill-mode: backwards;
  }
  49% {
    transform: scaleX(1);
    transform-origin: right;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left;
    animation-fill-mode: forwards;
  }
  100% {
    transform: scaleX(0);
    transform-origin: left;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--accent-primary);
  -webkit-box-shadow: 0 0 0 1000px var(--content-primary) inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* input:not(:placeholder-shown) + .label */
.nameInput:not(:placeholder-shown) + .nameLabel,
.emailInput:not(:placeholder-shown) + .emailLabel,
.nameInput:focus + .nameLabel,
.emailInput:focus + .emailLabel {
  color: rgba(0, 153, 229, 0.5);
  transform: translate(12px, -16px);
  font-size: 0.8rem;
}

.messageInput:not(:placeholder-shown) + .messageLabel,
.messageInput:focus + .messageLabel {
  color: rgba(0, 153, 229, 0.5);
  transform: translate(12px, -18px);
  font-size: 0.8rem;
}

.reply-container {
  margin-top: -4rem;
  margin-bottom: 2rem;
  background: var(--accent-primary);
  border-bottom-left-radius: 18px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  font-size: 1rem;
  font-weight: 500;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #fefefe;
  transform: scale(0);
  transform-origin: left;
}

.popMsg {
  animation: popMsg 4s both;
  animation-delay: 2s;
}

@keyframes popMsg {
  0% {
    transform: scaleX(0);
  }
  10% {
    transform: scaleX(0);
  }
  20% {
    transform: scaleX(1);
  }
  80% {
    transform: scaleX(1);
  }
  90% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(0);
  }
}

.contact-pager {
  opacity: 0;
  z-index: 20;
  pointer-events: none;
}

.contact-page {
  position: relative;
  z-index: 700;
  height: 100vh;
  width: 100vw;
  display: flex;
  background: #0d0e13;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fefefe;
}

.incog {
  position: relative;
  transform: scale(0.45);
  z-index: 3;
}

.incogImg {
  position: relative;
  z-index: 5;
  transform-origin: center;
  transform-box: fill-box;
}

.goback {
  animation: goback 4s ease both;
  animation-delay: 2s;
}
.gofront {
  animation: gofront 4s ease both;
  animation-delay: 2s;
}

.mypic {
  position: absolute;
  z-index: 4;
  transform-origin: center;
  transform-box: fill-box;
  transform: rotateY(180deg);
  top: 0;
  left: 0;
}

@keyframes goback {
  0% {
    transform: rotateY(0deg);
    z-index: 0;
  }
  10% {
    transform: rotateY(0deg);
    z-index: 0;
  }
  20% {
    transform: rotateY(180deg);
    z-index: -1;
  }
  80% {
    transform: rotateY(180deg);
    z-index: -1;
  }
  90% {
    transform: rotateY(0deg);
    z-index: 0;
  }
  100% {
    transform: rotateY(0deg);
    z-index: 0;
  }
}

@keyframes gofront {
  0% {
    transform: rotateY(180deg);
    z-index: -1;
  }
  10% {
    transform: rotateY(180deg);
    z-index: -1;
  }
  20% {
    transform: rotateY(0deg);
    z-index: 0;
  }
  80% {
    transform: rotateY(0deg);
    z-index: 0;
  }
  90% {
    transform: rotateY(180deg);
    z-index: -1;
  }
  100% {
    transform: rotateY(180deg);
    z-index: -1;
  }
}

body.bluebg {
  transition: background-color 1s ease-in-out;
  background-color: var(--content-primary);
}

.go-to-top {
  z-index: 100000;
  opacity: 0;
  cursor: pointer;
  transform: translateY(80px) scale(0.3);
  transition: 0.6s cubic-bezier(0.78, 0.78, 0.59, 1.94);
  width: 64px;
  height: 64px;
  background-image: linear-gradient(90deg, #0099e5 0%, #3354ff 100%);
  right: 24px;
  bottom: 54px;
  position: fixed;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.go-to-top:hover {
  transform: scale(0.85);
}

.goappear {
  transform: translateY(0px) scale(0.75);
  opacity: 1;
}

.deck-container {
  width: 100vw;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deck-btn {
  height: 60px;
  width: 264px;
  background: var(--accent-primary);
  font-size: 1rem;
  border-radius: 30px;
  color: #fefefe;
  font-weight: 600;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deck-btn::before {
  content: "";
  background: rgba(255, 255, 255, 0.1);
  height: 0%;
  width: 120%;
  left: 0;
  top: 0;
  position: absolute;
  transition: 0.8s ease-in-out;
  border-radius: 50% 50% 50% 50%;
  transform: translate(-10%, -50%);
}

.deck-btn:hover::before {
  color: var(--accent-primary);
  height: 350%;
  transform: translateY(0);
  border-radius: 0 0 0 0;
}

.go-to-top.display {
  transform: scale(0.8);
  opacity: 0.6;
}

.bordert,
.borderb,
.borderm,
.borderi,
.borderl {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-top: 2px solid var(--content-primary);
  border-bottom: 2px solid var(--content-primary);
  transform: scaleX(0);
  transition: 0.6s cubic-bezier(0.74, 0.15, 0.56, 1.01);
}

.s-i {
  display: block;
  position: relative;
  cursor: pointer;
  width: 100vw;
  height: 16vh;
  transform: scale(1);
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 5rem;
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
  /* -webkit-text-stroke-width: 3px; */
  -webkit-text-stroke-color: var(--content-primary);
}

.s-i:hover .bordert,
.s-i:hover .borderb,
.s-i:hover .borderm,
.s-i:hover .borderi,
.s-i:hover .borderl {
  opacity: 0.5;
  transform: scaleX(1);
}
.s-i:hover {
  cursor: none;
  color: var(--content-primary);
}

.social-logos {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: -100;
}

.social-logos div {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  bottom: -150px;
  animation: socialLogosAnimation 30s infinite linear;
}

.social-logos div:nth-child(1) {
  left: 5%;
  width: 48px;
  height: 48px;
  animation-delay: 1s;
  animation-duration: 10s;
}
.social-logos div:nth-child(2) {
  left: 85%;
  width: 48px;
  height: 48px;
  animation-delay: 2s;
  animation-duration: 10s;
}
.social-logos div:nth-child(3) {
  left: 55%;
  width: 32px;
  height: 32px;
  animation-delay: 0;
  animation-duration: 13s;
}
.social-logos div:nth-child(4) {
  left: 20%;
  width: 56px;
  height: 56px;
  animation-delay: 3s;
  /* animation-duration: 10s; */
}
.social-logos div:nth-child(5) {
  left: 40%;
  width: 16px;
  height: 16px;
  animation-delay: 4s;
  animation-duration: 16s;
}
.social-logos div:nth-child(6) {
  left: 90%;
  width: 32px;
  height: 32px;
  animation-delay: 6s;
  animation-duration: 16s;
}
.social-logos div:nth-child(7) {
  left: 35%;
  width: 48px;
  height: 48px;
  animation-delay: 5s;
  animation-duration: 10s;
}
.social-logos div:nth-child(8) {
  left: 75%;
  width: 80px;
  height: 80px;
  animation-delay: 6s;
  animation-duration: 20s;
}
.social-logos div:nth-child(9) {
  left: 50%;
  width: 48px;
  height: 48px;
  animation-delay: 8s;
  animation-duration: 10s;
}
.social-logos div:nth-child(10) {
  left: 45%;
  width: 48px;
  height: 48px;
  animation-delay: 8s;
  /* animation-duration: 10s; */
}

@keyframes socialLogosAnimation {
  0% {
    opacity: 1;
    border-radius: 0;
    transform: translateY(0) rotate(0deg);
  }
  100% {
    opacity: 0;
    border-radius: 50%;
    transform: translateY(-750px) rotate(720deg);
  }
}

/* About Page*/

.about-container {
  margin: 3rem auto;
  margin-bottom: 0 !important;
  display: flex;
  max-width: 1024px;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.about-text {
  max-width: 45%;
  height: auto;
  color: var(--content-primary);
}

.about-text p {
  margin-top: 1rem;
  color: var(--content-primary);
  line-height: 1.5;
}

.left,
.right {
  position: absolute;
  left: 0;
  top: 0;
}
.left-clip {
  transition: 0.7s ease-in-out;
  filter: brightness(0.7);
  clippath: polygon(0 0, 0 100%, 50% 100%);
}

.right-clip {
  transition: 0.7s ease-in-out;
  filter: brightness(0.7);
  clippath: polygon(100% 0, 100% 100%, 50% 100%);
}

.middle-clip {
  transition: 0.7s ease-in-out;
  clippath: polygon(0 0, 100% 0, 50% 100%);
}
.middle-zoom {
  transition: 0.7s ease-in-out;
  transform: scale(1.05);
}

.my-image-c {
  max-width: 35%;
  height: fit-content;
  position: relative;
  transform-style: preserve-3d;
}

.my-imaged-back:hover {
  transform: translateZ(30px);
  filter: drop-shadow(0px 48px 78px rgba(0, 0, 0, 0.3));
}

.my-image {
  transform-style: preserve-3d;
}

.my-imaged {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
}
.my-imaged-back {
  bottom: 4px;
  left: 0%;
  height: 100%;
  position: absolute;
  transform: translateZ(0px);
  transition: 0.5s;
  transform-origin: top;
  transform-style: preserve-3d;
}

::-webkit-scrollbar {
  width: 16px;
  /* box-shadow: inset 1px 0px 3px rgba(0, 0, 0, 0.5); */
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  margin: 0 4px;
  background: var(--content-secondary);
  border: 4px solid #fff;
}

.project-1 {
  max-width: 100vw;
  line-height: 2;
  overflow: hidden;
}

.p1-cover-image {
  top: 0;
  margin: auto;
}

.cover-bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 60vh;
}

.heading {
  position: absolute;
  font-size: 8rem;
  font-weight: 700;
  margin-left: 12px;
  margin-bottom: -30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading-footer {
  font-size: 16px;
  display: flex;
  width: 95%;
  align-items: center;
  justify-content: space-around;
  margin-top: -60px;
  margin-bottom: 0rem;
}

.heading-footer a {
  margin: 0 !important;
  width: 160px;
}

.headingflex {
  display: flex;
  flex-direction: row;
}

.heading p {
  text-shadow: 0px -3px 6px rgba(0, 0, 0, 0.3);
  letter-spacing: 6px;
}

.cover-bg h1 {
  text-align: center;
  font-size: 2.5rem;
}

.cover-bg img {
  position: absolute;
  height: 60vh;
  z-index: 0;
  margin-top: -30px;
}

.backtext {
  position: relative;
  z-index: -2;
}

.p1 {
  position: absolute;
  top: 0;
  height: 85vh;
  width: 100vw;
  z-index: -2;
  background: #ebf2fc;
  box-shadow: inset 0px -1px 8px rgba(0, 0, 0, 0.05);
}

.project-container {
  margin: auto;
  max-width: 1024px;
}

.pc1 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 7rem;
}

.pc1-sec {
  width: 40%;
}
.pc1-secs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pc1-sec p {
  margin-top: 2rem;
  color: var(--content-secondary);
}

.p-header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.p-header h2 {
  font-size: 2rem;
}

.p-header ul {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  font-size: 1.5rem;
}

.pw-images-bg {
  width: 100vw;
  background-color: #e4e4e4;
}

.pw-images {
  margin: 2rem auto;
  max-width: 1024px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pw-images img {
  height: 360px !important;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 5rem;
}

.lo-fi {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.lo-fi-img-1 {
  position: absolute;
  height: 90vh;
  margin-top: 10rem;
}
.lo-fi-img {
  margin-left: 60px;
  height: 90vh;
}

.lfp-bg {
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #e4e4e4;
}

.project-container a,
.about-container a {
  text-decoration: none;
  color: var(--link-primary);
  display: inline-block;
  font-weight: 500;
}

.project-container a:after,
.about-container a:after {
  display: block;
  content: "";
  border-bottom: solid 2px var(--link-primary);
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.project-container a:hover:after,
.about-container a:hover:after {
  transform: scale(1);
}

.project-container a:hover,
.about-container a:hover {
  animation: comeup 0.5s ease-in-out;
}

.up {
  display: flex;
  flex-wrap: wrap;
}

.upt {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  justify-content: flex-start;
}
.usability-2,
.usability {
  position: relative;
  display: flex;
}

.us-b-a {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.us-b-a-right-2,
.us-b-a-right {
  transform: translate(-50%, 0px);
}

.us-b-a-left-2 p,
.us-b-a-left p {
  transform: translate(0px, 30px);
}

.us-b-a-left-2,
.us-b-a-left {
  transform: translate(50%, 0px);
}

.flexed {
  margin-left: -22px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desktoplofi {
  width: 35vw;
  height: auto;
}

.mockups {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.mockups img {
  width: 256px;
}

.p-icon svg {
  transform: scale(1.5);
}

.trans {
  transform: translate(25%, -25%) scale(1.5) !important;
}

#Group_74 {
  animation: g74 2s cubic-bezier(0.74, 0.15, 0.37, 0.91) alternate infinite;
}

@keyframes g74 {
  0% {
    transform: translate(-6.967px, -2.333px);
  }
  50% {
    transform: translate(-4.967px, -2.333px);
  }
  100% {
    transform: translate(-4.967px, -2.333px);
  }
}

#Path_84 {
  animation: p84 2s cubic-bezier(0.74, 0.15, 0.37, 0.91) infinite alternate;
}
#Path_87 {
  animation: p87 2s cubic-bezier(0.74, 0.15, 0.37, 0.91) infinite alternate;
}
#Path_88 {
  animation: p88 2s cubic-bezier(0.74, 0.15, 0.37, 0.91) infinite alternate;
}

@keyframes p84 {
  0% {
    d: path(
      "M.5,0h2A.452.452,0,0,1,3,.5v2c0,.415-.224.5-.5.5H.5A.5.5,0,0,1,0,2.5V.5A.5.5,0,0,1,.5,0Z"
    );
    transform: translate(3660.967px, 2604.333px);
  }
  50% {
    d: path(
      "M.5,0h2c.276,0,.294.2.5.5L4.031.969v1.1L3,2.5c-.319.216-.224.5-.5.5H.5A.5.5,0,0,1,0,2.5V.5A.5.5,0,0,1,.5,0Z"
    );
    transform: translate(3656.967px, 2604.333px);
  }
  100% {
    d: path(
      "M.5,0h2A.452.452,0,0,1,3,.5v2c0,.415-.224.5-.5.5H.5A.5.5,0,0,1,0,2.5V.5A.5.5,0,0,1,.5,0Z"
    );
    transform: translate(3655.967px, 2604.333px);
  }
}

@keyframes p87 {
  0% {
    d: path(
      "M.5,0h2A.452.452,0,0,1,3,.5v2c0,.415-.224.5-.5.5H.5A.5.5,0,0,1,0,2.5V.5A.5.5,0,0,1,.5,0Z"
    );
    transform: translate(3660.967px, 2609.333px);
  }
  50% {
    d: path(
      "M.5,0h2c.276,0,.294.2.5.5L4.031.969v1.1L3,2.5c-.319.216-.224.5-.5.5H.5A.5.5,0,0,1,0,2.5V.5A.5.5,0,0,1,.5,0Z"
    );
    transform: translate(3656.967px, 2609.333px);
  }
  100% {
    d: path(
      "M.5,0h2A.452.452,0,0,1,3,.5v2c0,.415-.224.5-.5.5H.5A.5.5,0,0,1,0,2.5V.5A.5.5,0,0,1,.5,0Z"
    );
    transform: translate(3655.967px, 2609.333px);
  }
}

@keyframes p88 {
  0% {
    d: path(
      "M.5,0h2A.452.452,0,0,1,3,.5v2c0,.415-.224.5-.5.5H.5A.5.5,0,0,1,0,2.5V.5A.5.5,0,0,1,.5,0Z"
    );
    transform: translate(3660.967px, 2614.333px);
  }
  50% {
    d: path(
      "M.5,0h2c.276,0,.294.2.5.5L4.031.969v1.1L3,2.5c-.319.216-.224.5-.5.5H.5A.5.5,0,0,1,0,2.5V.5A.5.5,0,0,1,.5,0Z"
    );
    transform: translate(3656.967px, 2614.333px);
  }
  100% {
    d: path(
      "M.5,0h2A.452.452,0,0,1,3,.5v2c0,.415-.224.5-.5.5H.5A.5.5,0,0,1,0,2.5V.5A.5.5,0,0,1,.5,0Z"
    );
    transform: translate(3655.967px, 2614.333px);
  }
}

#Ellipse_39 {
  animation: e39 2s cubic-bezier(0.74, 0.15, 0.37, 0.91) infinite alternate;
}

@keyframes e39 {
  0% {
    transform: translate(3511px, 2595px) scale(0);
  }
  50% {
    transform: translate(3511px, 2611px) scale(1);
  }
  100% {
    transform: translate(3511px, 2611px);
  }
}

#Rectangle_110 {
  animation: r110 2s cubic-bezier(0.74, 0.15, 0.37, 0.91) infinite alternate;
}
#Rectangle_111 {
  animation: r111 2s cubic-bezier(0.74, 0.15, 0.37, 0.91) infinite alternate;
  animation-delay: 0.5s;
}
#Rectangle_112 {
  animation: r112 2s cubic-bezier(0.74, 0.15, 0.37, 0.91) infinite alternate;
}

@keyframes r110 {
  0% {
    transform: translate(3655px, 2659px) scaleY(-0.3);
  }
  50% {
    transform: translate(3655px, 2659px) scaleY(-0.6);
  }
  100% {
    transform: translate(3655px, 2659px) scaleY(-0.6);
  }
}

@keyframes r111 {
  0% {
    transform: translate(3663px, 2659px) scaleY(-0.4);
  }
  50% {
    transform: translate(3663px, 2659px) scaleY(-0.8);
  }
  100% {
    transform: translate(3663px, 2659px) scaleY(-0.8);
  }
}
@keyframes r112 {
  0% {
    transform: translate(3659px, 2659px) scaleY(-0.5);
  }
  50% {
    transform: translate(3659px, 2659px) scaleY(-1);
  }
  100% {
    transform: translate(3659px, 2659px) scaleY(-1);
  }
}

#Rectangle_116 {
  animation: r116 2s cubic-bezier(0.74, 0.15, 0.37, 0.91) infinite alternate;
}
@keyframes r116 {
  0% {
    transform: translate(3665px, 2659px) rotate(270);
    height: 5px;
  }
  50% {
    transform: translate(3665px, 2659px) rotate(270);
    height: 8px;
  }
  100% {
    transform: translate(3665px, 2659px) rotate(270);
    height: 8px;
  }
}

#star {
  animation: star 2s cubic-bezier(0.74, 0.15, 0.37, 0.91) infinite alternate;
  transform-box: fill-box;
  transform-origin: center;
}
@keyframes star {
  0% {
    transform: translate(3556.559px, 2596.001px) scale(0.5) rotateY(360deg);
  }
  50% {
    transform: translate(3556.559px, 2596.001px) scale(1);
  }
  100% {
    transform: translate(3556.559px, 2596.001px) scale(1);
  }
}

#location {
  animation: loc 2s infinite alternate;
}
@keyframes loc {
  0% {
    transform: translate(3645.5px, 2688px);
  }
  20% {
    transform: translate(3645.5px, 2686px);
  }
  40% {
    transform: translate(3645.5px, 2688px);
  }
  60% {
    transform: translate(3645.5px, 2688px);
  }
  80% {
    transform: translate(3645.5px, 2688px);
  }
  100% {
    transform: translate(3645.5px, 2688px);
  }
}
#Ellipse_54 {
  animation: e54 2s infinite alternate;
}
@keyframes e54 {
  0% {
    transform: translate(3653px, 2710px) scale(1);
  }
  20% {
    transform: translate(3656px, 2710px) scale(0.5);
  }
  40% {
    transform: translate(3653px, 2710px) scale(1);
  }
  60% {
    transform: translate(3653px, 2710px) scale(1);
  }
  80% {
    transform: translate(3653px, 2710px) scale(1);
  }
  100% {
    transform: translate(3653px, 2710px) scale(1);
  }
}

/* #part-icon{animation: part-icon 2s infinite alternate;} */
#part-icon-2 {
  animation: part-icon-2 2s infinite alternate;
}
#Group_83 {
  animation: g83 2s infinite alternate;
}
#Group_82 {
  animation: g82 2s infinite alternate;
}
@keyframes part-icon {
  0% {
    transform: translate(-3686px, -2683px);
  }
  50% {
    transform: translate(-3730px, -2683px);
  }
  100% {
    transform: translate(-3730px, -2683px);
  }
}
@keyframes g83 {
  0% {
    transform: translate(98.905px, 98.125px);
  }
  50% {
    transform: translate(88.905px, 98.125px);
  }
  100% {
    transform: translate(88.905px, 98.125px);
  }
}
@keyframes g82 {
  0% {
    transform: translate(87.905px, 94px);
  }
  50% {
    transform: translate(93.905px, 94px);
  }
  100% {
    transform: translate(93.905px, 94px);
  }
}

#nail {
  animation: nail 2s infinite alternate;
  transform-origin: bottom;
  transform-box: fill-box;
}
@keyframes nail {
  0% {
    transform: translate(3873.5px, 2696px) rotateZ(0deg);
  }
  25% {
    transform: translate(3873.5px, 2696px) rotateZ(90deg);
  }
  50% {
    transform: translate(3873.5px, 2696px) rotateZ(0deg);
  }
  100% {
    transform: translate(3873.5px, 2696px) rotateZ(0deg);
  }
}

#Path_89 {
  animation: p89 2s infinite alternate;
}
@keyframes p89 {
  0% {
    d: path("M3655.806,2654h0");
    transform: translate(-0.198px);
  }
  25% {
    d: path("M3655.806,2654l2.789,2.789h0");
    transform: translate(2.799px);
  }
  50% {
    d: path("M3655.806,2654l2.789,2.789,5.993-5.993");
    transform: translate(-0.198px);
  }
  100% {
    d: path("M3655.806,2654l2.789,2.789,5.993-5.993");
    transform: translate(-0.198px);
  }
}

#Line_25 {
  animation: l25 2s infinite alternate;
}

@keyframes l25 {
  0% {
    transform: translate(3832.5px, 2704px);
  }
  50% {
    transform: translate(3832.5px, 2704px);
    x2: 2px;
  }
  100% {
    transform: translate(3832.5px, 2704px);
  }
}

.other-projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ebf2fc;
}

.other-projects-container h2 {
  padding-top: 3rem;
}

.other-projects {
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  justify-content: space-around;
}
.op {
  max-width: 520px;
  min-height: 296px !important;
  margin: 2rem;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0px 4px 24px rgba(18, 45, 52, 0.08);
  cursor: none !important;
}

nav {
  height: 20vh;
  background: cornflowerblue;
}

.updates-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.update-page {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.updates-page ul {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.updates-page ul li {
  list-style: none;
  margin-top: 0.5rem;
}
.updates-page {
  overflow: hidden;
  position: relative;
  color: #0d0e13 !important;
}

.link-style {
  text-decoration: none;
  color: #0d0e13;
}

.updates-page p {
  margin-top: 2rem;
}

.updates-page {
  width: 100%;
  background-image: url("https://d39w1it2s36axc.cloudfront.net/images/images/web-new-bg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.link-style {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.link-style::before {
  top: 0;
  left: 0;
  position: absolute;
  transition: width 0.8s ease;
  height: 100%;
  width: 0%;
  z-index: -1;
  content: "";
  background: rgba(0, 153, 229, 0.5);
}

.link-style:hover::before {
  width: 100%;
}
.display-none {
  display: none;
}

@media screen and (max-width: 780px) {
  .movable-header {
    font-size: 2rem;
  }
  .res-d-none {
    display: none;
  }

  .updates-page {
    background-image: url("https://d39w1it2s36axc.cloudfront.net/images/images/ipad-new-bg.png");
  }

  .update-page ul {
    margin-left: -12px;
  }

  .stack-stuff div {
    transform: scale(0.9);
  }
  .oBg {
    width: 100%;
    transform: rotate(90deg);
  }
  .mBg {
    width: 50%;
    transform: rotate(90deg);
  }
  .iBg {
    width: 25%;
    transform: rotate(90deg);
  }

  .oBgo {
    width: 100%;
  }
  .mBgo {
    width: 75%;
  }
  .iBgo {
    width: 50%;
  }

  .input-container input,
  .input-container textarea {
    width: 420px;
  }
  .main-header {
    font-size: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 5rem;
  }

  .para {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .center p {
    margin-top: 24px;
  }
  .cube {
    width: 120px;
  }

  .projects {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .p-3 {
    margin-top: 4rem !important;
  }

  .s-i {
    font-size: 3rem;
    -webkit-text-stroke-width: 1px;
  }

  .about-container {
    margin: 1rem auto;
    flex-direction: column-reverse;
  }
  .about-text {
    max-width: 90%;
  }
  .my-image-c {
    max-width: 90%;
    background: #f8f8f8;
  }

  .leftalign {
    align-items: flex-start !important;
  }
  .rwidth100 {
    width: 100% !important;
    text-align: left !important;
  }

  .pc1-secs,
  .pc1-sec {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
  }
  .rmt-5 {
    margin-top: 5rem;
  }

  .persona-conts,
  .persona-cont {
    margin: auto;
    width: 90% !important;
  }

  .persona-cont p {
    width: auto !important;
  }
  .lofi-pimg {
    width: 90vw;
  }

  .rtrans {
    transform: translate(25%, -25%) scale(1.5) !important;
  }

  .p-details {
    flex-direction: column;
    align-items: flex-start;
  }

  .p-details img {
    margin: auto;
    height: 120px;
  }
  .details {
    margin-top: 2rem;
  }
  .upt {
    padding: 0;
    width: 50%;
    margin-top: 5rem;
  }

  .us-b-a img {
    width: 45vw !important;
    height: auto !important;
  }

  .us-b-a p {
    font-size: 0.75rem !important;
    margin-left: -28px !important;
  }

  .mockups img {
    margin-left: 12%;
    width: 286px;
  }

  .cover-bg img {
    height: auto;
    width: 80vw;
  }

  .usability {
    margin-left: 1.5rem;
  }

  .m53 {
    margin: 5rem 3rem !important;
  }
  .pw-images img {
    height: 280px !important;
  }

  .heading {
    font-size: 7rem;
  }
}
.menubar {
  display: none;
  z-index: 5;
}

/*      Course Projects      */

.course-projects-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.course-projects-container h1 {
  font-size: 5rem;
  color: transparent;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 2rem;
  /* background-image: linear-gradient(90deg,#425EF0, #42B6F0, #D342F0, #F04299); */
  background-image: linear-gradient(90deg, #0099e5 0%, #3354ff 100%);
  -webkit-background-clip: text;
}

.course-project-tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  gap: 40px;
}

.course-project-tile {
  width: 90%;
  max-width: 460px;
  height: 520px;
  border-radius: 24px;
  transition: 0.5s ease;
  overflow: hidden;
  padding: 1rem;
  border: 1px solid #eee;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.course-project-img {
  width: 100%;
  height: 320px;
  object-fit: cover;
  border-radius: 16px;
  overflow: hidden;
}

.course-project-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  transition: 0.5s ease;
}

.course-project-tile:hover img {
  scale: 1.1;
}

.course-project-tile:hover {
  box-shadow: 0px 16px 64px rgba(13, 14, 19, 0.08);
  border: 1px solid #fff;
}

.stacked_project-container li,
.course-project-tile li {
  padding-bottom: 1rem;
  /* display: flex; */
  /* gap: 4px; */
  margin: 0;
}

.stacked_project-container li a,
.course-project-tile li a {
  /* text-decoration: underline; */
  position: relative;
  font-weight: 600;
}

.stacked_project-container li a::before,
.course-project-tile li a::before {
  /* text-decoration: underline; */
  content: "";
  width: 0%;
  height: 1.5px;
  background: var(--content-primary);
  position: absolute;
  left: 0;
  bottom: -2px;
  transition: 0.5s ease;
}

.stacked_project-container li a:hover::before,
.course-project-tile li a:hover::before {
  width: 100%;
}

.stacked_project-container {
  width: 90%;
  max-width: 460px;
  height: 520px;
  transition: 0.5s ease;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stacked_project-front {
  position: relative;
  width: 100%;
  height: 320px;
  border-radius: 40px;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 4px 48px 0px rgba(28, 27, 44, 0.06);
  z-index: 2;
}

.stacked_project-back {
  position: relative;
  width: 90%;
  height: 320px;
  border-radius: 40px;
  padding: 1.5rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 4px 48px 0px rgba(28, 27, 44, 0.08);
  z-index: 1;
  transform: translateY(-208px);
}

.stacked_project-hover {
  animation: stacked_project-front 0.8s ease all;
}

@keyframes stacked_project-front {
  0% {
    transform: translateY(-120px);
  }
  49% {
    transform: translateY(0px);
    z-index: 2;
  }
  51% {
    transform: translateY(0px);
    z-index: 3;
  }
  100% {
    transform: translateY(-120px);
    z-index: 3;
  }
}

.stacked_project-img {
  width: 100%;
  border-radius: 24px;
  height: 240px;
  overflow: hidden;
}

.stacked_project-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s ease;
}

.stacked_project-container h2 {
  color: var(--content-primary);
  font-size: 24px;
  font-weight: 500;
}

.stacked_project-front p {
  color: var(--content-secondary);
  font-size: 20px;
  font-weight: 400;
  margin-top: -8px;
  margin-bottom: 24px;
}

.stacked_project-container li {
  padding-bottom: 1rem;
  margin: 0;
}

.stacked_project-container li:last-child {
  padding-bottom: 0;
}

.video-outputs {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

#overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

#video-container {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

#close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
}

/*      Interactions      */

.int-card {
  width: 90%;
  max-width: 460px;
  height: 520px;
  border-radius: 24px;
  transition: 0.5s ease;
  overflow: hidden;
  padding: 1rem;
  background: #fafdff;
  display: flex;
  gap: 1rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.int-card-left {
  opacity: 0.4;
  transition: 0.5s ease;
}

.int-card:hover .int-card-left {
  opacity: 1;
}

.interactionIphone img {
  transition: 0.5s ease;
}

.int-card:hover .interactionIphone img {
  scale: 1.01;
}

.int-card:hover .iphone-gif {
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3);
}

.dim {
  opacity: 0.2;
}

.normal-gif {
  height: 90%;
  object-fit: cover;
  border-radius: 32px;
  border: 8px solid white;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
}

.iphone-gif {
  position: absolute;
  width: 41%;
  object-fit: cover;
  border-radius: 24px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
}

.interactionIphone {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: 0.5s ease;
}

/* .interactionIphone img{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 84%;
  border-radius: 24px;
  object-fit: cover;
} */

.interactionIphone .iphone {
  height: 90%;
  z-index: 2;
}

/* .interactionIphone img:last-child{
  transform: translate(-50.3%, -50%);
} */

.bunker_interaction {
  left: 50%;
  object-fit: cover;
  position: absolute;
  border: 8px solid #fff;
  border-radius: 32px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
  top: 50%;
  transform: translate(-50%, -50%) !important;
  width: 90%;
  height: auto !important;
}

@media screen and (max-width: 1024px) {
  .desktop-d-none {
    display: block;
  }

  .mobile-d-none {
    display: none;
  }

  .course-projects-container h1 {
    font-size: 3rem;
  }

  .project-new-container h2 {
    font-size: 2rem !important;
  }

  .project-new-container p {
    font-size: 1rem;
  }

  .project {
    width: 100%;
  }
  .p-3 {
    margin-top: 4rem;
  }

  .lo-fi-img-1,
  .lo-fi-img {
    width: 90vw;
  }
  .cursor-follower {
    display: none !important;
  }

  .just {
    flex-direction: column;
  }

  .desktoplofi {
    width: 90vw !important;
  }
  .error_text {
    min-width: 300px;
  }
  .error-bg {
    background-image: url("https://d39w1it2s36axc.cloudfront.net/images/images/404_mobile.png");
  }
  .network-bg img {
    transform: scale(2);
    left: 120px;
  }

  .menubar {
    display: block;
    padding-left: 12px;
    padding-right: 12px;
  }

  .navbar {
    overflow: hidden;
    height: 120px;
    justify-content: space-around;
  }

  .logoC {
    z-index: 25;
    width: 60px;
    margin: initial !important;
  }
  li {
    display: inline-block;
    overflow: hidden;
    margin: 1rem 1rem;
  }

  li a {
    position: absolute;
  }
  .menu-bg {
    font-size: 1.75rem !important;
  }
  .menu {
    cursor: pointer;
    overflow: hidden;
    position: fixed;
    transform: scale(0.8) translateX(120%);
    transition: 0.8s ease-out;
    transform-origin: right;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    background: #fbfdfe;
    box-shadow: -4px 6px 8px rgb(0, 0, 0, 0.12);
    z-index: 2;
  }

  .menubar {
    transform-box: fill-box;
    transform-origin: center;
  }
  #Rectangle_136 {
    transform: translate(131.838px, 139.593px);
    animation-direction: normal;
  }
  #Rectangle_137 {
    transform: translate(131.838px, 148.593px);
    animation-direction: normal;
  }
  #Rectangle_138 {
    transform: translate(131.838px, 157.593px);
    animation-direction: normal;
  }
  .r136 {
    animation: r136 0.5s ease-out forwards;
  }
  .r137 {
    animation: r137 0.5s ease-out forwards;
  }
  .r138 {
    animation: r138 0.5s ease-out forwards;
  }

  @keyframes r136 {
    25% {
      transform: translate(160.338px, 132.093px) rotate(90deg);
    }
    50% {
      transform: translate(169.991px, 144.79px) rotate(135deg);
    }
    75% {
      transform: translate(163.627px, 138.421px) rotate(135deg);
    }
    100% {
      transform: translate(163.627px, 138.421px) rotate(135deg);
    }
  }
  @keyframes r137 {
    25% {
      transform: translate(151.338px, 132.093px) rotate(90deg);
    }
    50% {
      transform: translate(163.627px, 138.426px) rotate(135deg);
    }
    75% {
      transform: translate(163.627px, 138.426px) rotate(135deg);
    }
    100% {
      transform: translate(163.627px, 138.421px) rotate(135deg);
    }
  }
  @keyframes r138 {
    25% {
      transform: translate(142.338px, 132.093px) rotate(90deg);
    }
    50% {
      transform: translate(157.263px, 132.062px) rotate(135deg);
    }
    75% {
      transform: translate(163.627px, 138.421px) rotate(135deg);
    }
    100% {
      transform: translate(161.506px, 163.877px) rotate(225deg);
    }
  }
  .menu.slide {
    transform: scale(1) translateX(0%);
    overflow-y: hidden;
    pointer-events: all;
  }
  .stop-scrolling {
    height: 100%;
    overflow: hidden;
  }
}

.bunker svg {
  width: 100vw;
  height: 100%;
}

.bunker {
  overflow: hidden;
  position: relative;
  transform: perspective(200px) translateZ(0px) rotate(0deg);
  transition: all ease;
}

#bunker-color-1,
#bunker-color-2,
#bunker-color-3,
#bunker-color-4 {
  fill: #0099e5;
}

.redAlert1to4 {
  -webkit-animation: redAlertAnim1to4 0.5s ease forwards;
}
.redAlert5to10 {
  -webkit-animation: redAlertAnim5to10 0.5s ease forwards;
}

@keyframes redAlertAnim1to4 {
  0% {
    fill: #fec264;
  }
  100% {
    fill: rgba(252, 155, 100, 1);
  }
}

@keyframes redAlertAnim5to10 {
  0% {
    stroke: #fec264;
  }
  100% {
    stroke: rgba(252, 155, 100, 1);
  }
}

.greenAlert1to4 {
  -webkit-animation: greenAlertAnim1to4 0.5s ease forwards;
}
.greenAlert5to10 {
  -webkit-animation: greenAlertAnim5to10 0.5s ease forwards;
}

@keyframes greenAlertAnim1to4 {
  0% {
    fill: #0099e5;
  }
  100% {
    fill: #9efc64;
  }
}

@keyframes greenAlertAnim5to10 {
  0% {
    stroke: #0099e5;
  }
  100% {
    stroke: #9efc64;
  }
}

#bunker-color-5,
#bunker-color-6,
#bunker-color-7,
#bunker-color-8,
#bunker-color-9,
#bunker-color-10 {
  stroke: #0099e5;
}

#left_door {
  animation: slideLeft 2s ease;
}

#right_door {
  animation: slideRight 2s ease;
}

#door-background {
  fill: #010101;
}

.door-background-animation {
  animation: colorChange 2s ease forwards;
}

@keyframes colorChange {
  0% {
    fill: #010101;
  }
  100% {
    fill: #ffffff;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-120px);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(120px);
  }
}

.bunker-inputs {
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
}

#gnctdPasscode {
  height: 52px;
  width: 128px;
  background: #5e5e5e;
  border: 2px solid #0099e5;
  border-radius: 12px;
  font-size: 24px;
  padding-left: 24px;
  font-weight: 600;
  color: #fff;
  transition: 0.5s ease;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3),
    inset 4px 4px 4px rgba(255, 255, 255, 0.08),
    inset 0 0 8px rgba(255, 255, 255, 0.2);
}

#gnctdPasscode:focus {
  outline: none;
  transform: translateY(-2px);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3), 0 4px 8px rgba(0, 153, 229, 0.2),
    inset 0 4px 8px rgba(0, 0, 0, 0.15);
}

#gnctdPasscode::placeholder {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}

#bunker-pin-button {
  height: 56px;
  width: 56px;
  background: #0099e5;
  border-radius: 12px;
  margin-top: 0px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3),
    inset 4px 4px 4px rgba(255, 255, 255, 0.16),
    inset 0 0 8px rgba(255, 255, 255, 0.2);
}

.bunker-inputs svg {
  width: 32px;
}

.bunker-door-left {
  animation: slideLeft 3s ease-in forwards;
}

.bunker-door-right {
  animation: slideRight 3s ease-in forwards;
}

@keyframes slideLeft {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-200px);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(200px);
  }
}

.bunker-zoomin {
  animation: bunkerZoomIn 4s forwards;
  transition: ease-in;
}

@keyframes bunkerZoomIn {
  0% {
    transform: perspective(200px) translateZ(0px) rotate(0deg);
  }
  50% {
    transform: perspective(200px) translateZ(0px) rotate(0deg);
  }
  100% {
    transform: perspective(200px) translateZ(720px) rotate(-15deg);
  }
}

.LED-Stripe {
  display: flex;
  flex-direction: row;
  gap: 12px;
  position: absolute;
  left: 50%;
  top: 5%;
  transform: translateX(-50%) scale(0.9);
  border-radius: 12px;
  background: #010101;
  transition: all 0.5s ease;
  transform-origin: center;
  padding: 8px 32px;
  border: 2px solid #656565;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25) inset 0 4px 8px rgb(0, 0, 0, 0.11);
}

.LED-Stripe p {
  font-size: 20px;
  font-weight: 400;
  font-family: "Digital Numbers", sans-serif;
  color: #ff5353;
  text-shadow: 0px 0px 8px rgba(255, 37, 37, 0.75);
}

.Alert-Overlay {
  opacity: 0;
  pointer-events: all;
  background-image: radial-gradient(
    circle,
    rgba(255, 94, 94, 0),
    rgba(255, 94, 94, 0.08)
  );
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.5s ease both;
  top: 0;
  left: 0;
}

.resources-page {
  width: 100%;
  margin: 3rem auto;
  max-width: 1024px;
}

.resources-page h1 {
  width: 100%;
  text-align: center;
}

.resources-container {
  width: 100%;
  height: 100%;
  padding-top: 3rem;
}

.resources-container img {
  height: 366px;
  width: 488px;
  border-radius: 48px;
  box-shadow: 0px 4px 32px rgba(18, 45, 52, 0.08);
}

.social_media-page {
  width: 100%;
  margin: 3rem auto;
  max-width: 1024px;
}

.social_media-page h1 {
  width: 100%;
  text-align: center;
}

.social_media {
  display: flex;
  flex-direction: row;
  gap: 128px;
  align-items: center;
  justify-content: center;
}

.social_media-container {
  width: 100%;
  height: 100%;
  padding-top: 3rem;
  gap: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.social_media-thumbnail {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.social_media-white {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 128px;
  width: 128px;
  overflow: hidden;
  border-radius: 4px 0 4px 4px;
  background-image: radial-gradient(rgb(255, 255, 255), rgb(240, 240, 240));
  box-shadow: 0px 1px 4px rgba(18, 45, 52, 0.08);
}

.social_media-white img {
  width: 128px;
  height: 128px;
}

.image-download {
  width: 48px;
  height: 48px;
  border-radius: 4px 4px 0 0;
  background: rgb(240, 240, 240);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-download:hover {
  background: #0099e5;
}

#bmc-wbtn {
  bottom: 48px;
  right: 48px !important;
  box-shadow: 0px 4px 24px rgba(18, 45, 52, 0.28) !important;
  transform: scale(1.25) !important;
}

#bmc-wbtn:hover {
  transform: scale(1.4) !important;
}

body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

/*       ARTVERSE      */

.artverse {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.artverse h1 {
  font-size: 2rem;
}

.artverse p {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  color: #444;
  letter-spacing: 0.2px;
}

/* .artverse .top{
  width: 95%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
} */

.artverse .top ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  width: 100%;
  gap: 1rem;
}

.artverse .top ul li p:first-child {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--content-secondary);
}

.artverse .video {
  width: 100%;
  max-width: 1024px;
  height: 100%;
  object-fit: cover;
  margin-top: 4rem;
  aspect-ratio: 1920 / 1080;
  border-radius: 24px;
}

.artverse section {
  margin-top: 8rem;
  width: 100%;
  max-width: 800px;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.artverse .section-image {
  margin: 10rem 0;
}

/* .artverse .list-style-decimal ul li{
  list-style: decimal;
  margin-left: 2rem;
  margin-top: 1rem;
  color: var(--content-secondary);
} */

.list-block {
  background: #f5fbff;
  /* background: rgba(0,0,0,0.03); */
  padding: 1rem;
  border-radius: 1rem;
}

.gardentag .list-block,
.gardentag .artverse_block,
.gardentag .artverse_block_items,
.gardentag .artverse_block_column {
  background: #f8fcfa;
}

.projectHeader {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 120px;
  align-items: center;
  width: 100%;
}

.artverse .number {
  position: absolute;
  font-size: 96px;
  font-weight: 900;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.artverse .emoji {
  height: 96px;
}

.artverse .team {
  width: 95%;
  max-width: 1024px;
  aspect-ratio: 1920 / 1080;
  object-fit: cover;
  border-radius: 24px;
}

.artverse_bg {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
}

.artverse_bg p {
  font-size: 1rem;
}

.artverse_block {
  display: flex;
  flex-direction: row;
  background: #f5fbff;
  border-radius: 24px;
  overflow: hidden;
}

.artverse_block_column {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #f5fbff;
  border-radius: 24px;
  overflow: hidden;
  width: 100%;
}

.artverse_block_column .h320 {
  max-height: 320px;
  object-fit: cover;
}

.artverse_block img {
  width: 100%;
  max-width: 300px;
  object-fit: cover;
}

.artverse_block_items {
  padding: 2rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.artverse .card-img {
  /* max-width: 400px; */
  height: 240px;
  object-fit: cover;
}

.card-img-l {
  height: 280px;
  object-fit: cover;
}

.fdrow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.fdcolumn {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 1024px;
}

.gt_quote_r {
  position: absolute;
  z-index: 0;
  height: 40px;
  right: 32px;
  bottom: 24px;
}

.hifi_screens {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.spline-watermark {
  display: none !important;
}

#gardentag_img_sequence {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1656/1198;
}

.gardentag_header {
  display: inline-block;
  white-space: pre;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(90deg, #39ca09, #063218);
  font-size: 5rem;
  font-weight: 700;
  opacity: 0;
  transform: translateY(20px) scale(0.5);
}

.gardentag_tagline {
  opacity: 0;
  transform: translateY(40px);
  font-size: 2rem;
}

.gardentag_product {
  margin-top: -4rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gardentag_sensors {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 1rem;
  width: 100%;
  max-width: 1024px;
  opacity: 0;
}

.gardentag_sensors_top,
.gardentag_sensors_bottom {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.gardentag_sensors_item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 2rem;
  gap: 0.75rem;
  background-image: linear-gradient(135deg, #eafbf1, #f9fcf7);
  padding: 2rem;
}

.gardentag_sensors_item p {
  font-size: 18px;
  line-height: 24px;
}

.gardentag_sensors_number {
  height: 230px;
}

.gt_appendix {
  position: relative;
  width: 100%;
  border-radius: 24px;
  overflow: hidden;
}

.gt_appendix img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
  transition: 1s ease;
}

.gt_appendix a {
  position: absolute;
  top: 92%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* opacity: 0; */
  transition: 1s ease;
  color: #000 !important;
  z-index: 4;
  font-size: 20px;
  font-weight: 500;
}

.gt_appendix:hover a {
  top: 90%;
}

.gt_appendix::before {
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.2);
  content: "";
  position: absolute;
  transition: 1s ease;
  top: 0;
  left: 0;
  z-index: 3;
}

.gt_appendix:hover img {
  transform: scale(1.05);
}

.gt_appendix:hover::before {
  opacity: 1;
}

.gt_hifi {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.gt_hifi_img_wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  transition: transform 0.5s ease-out;
}

.gt_hifi_img {
  object-fit: cover;
  max-width: 272px;
}

.gt_hifi_img_2 {
  animation: gt_hifi_img_down 30s linear both infinite;
}

.gt_hifi_img_1 {
  animation: gt_hifi_img_up 30s linear both infinite;
}

@keyframes gt_hifi_img_down {
  0% {
    transform: translateY(16%);
  }
  50% {
    transform: translateY(-16%);
  }
  100% {
    transform: translateY(16%);
  }
}

@keyframes gt_hifi_img_up {
  0% {
    transform: translateY(-16%);
  }
  50% {
    transform: translateY(16%);
  }
  100% {
    transform: translateY(-16%);
  }
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  /* margin: 2rem 0; */
}

/* Hide slides by default */
.mySlides {
  display: none;
}

.mySlides img {
  border-radius: 24px;
  object-fit: cover;
  aspect-ratio: 1920 / 1080;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
  color: var(--content-secondary);
  font-size: 15px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Fading animation */
.fade {
  display: block !important;
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 520px) {
  .iphone-gif {
    width: 54.5%;
  }

  .artverse,
  .gardentag {
    margin: 0 1rem;
    width: auto;
  }

  .artverse .top ul {
    flex-direction: column;
    gap: 0.5rem;
  }

  .hola_hover:hover::before {
    transform: translate(-65%, -65%) scale(0.5);
  }

  .sarath_hover:hover::before {
    transform: translate(65%, -65%) scale(0.5);
  }

  .course-project-tile:last-child {
    margin-bottom: 2rem;
  }
  .course-projects-container h1 {
    font-size: 2rem;
  }

  .p-0 img:first-of-type {
    bottom: 200px;
    left: 40px;
  }

  .p-0 img:last-of-type {
    top: 20px;
    right: -75%;
  }

  .project-new-container {
    height: 400px;
    padding: 2rem;
  }

  .update-page {
    padding: 3rem;
  }
  .updates-page {
    background-image: url("https://d39w1it2s36axc.cloudfront.net/images/images/mob-new-bg.png");
  }

  .stackH3 {
    width: 92%;
    text-align: center;
  }

  .insp {
    right: 5%;
  }
  .hours,
  .thinking {
    left: 65%;
  }
  .jspng,
  .gsappng {
    left: 15%;
  }
  .blenderpng {
    left: 5%;
  }

  .cover-bg img {
    margin-top: -140px !important;
  }

  .mt-180 {
    margin-top: -180px !important;
  }

  .contact-page h2 {
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .input-container input,
  .input-container textarea {
    width: 300px;
  }

  .main-header {
    font-size: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .para {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .center p {
    margin-top: 24px;
  }
  .cube {
    width: 120px;
  }

  .projects {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .p-3 {
    margin-top: 4rem !important;
  }

  .s-i {
    font-size: 2rem;
    -webkit-text-stroke-width: 1px;
  }

  .heading {
    font-size: 5rem;
    line-height: 1;
  }

  .cover-bg {
    height: 55vh;
  }

  .cover-bg h1 {
    margin-top: 30px;
    font-size: 2rem;
  }
  .headingflex {
    flex-direction: column;
    align-items: center;
  }
  .newline {
    line-break: anywhere;
  }
}

@media screen and (max-width: 320px) {
  html {
    font-size: 90%;
  }
  .m53 {
    margin: 5rem 1rem !important;
  }

  .lo-fi h2 {
    margin: auto;
  }
}

@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important;
    animation-duration: -1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}
